import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import { FiChevronsUp, FiChevronsDown } from 'react-icons/fi'
import { AutoSizer, Table, Column } from 'react-virtualized'
import SearchSelect from '../common/searchSelect/SearchSelect'
import Popup from '../common/popup/Popup'

import { getSymbolAttributeByName, getSymbolExpiryDate, isDynamicFundingRateSymbol, getSymbolFundingRatePrecision, getPricePrecisionBySymbolItem, getTokenPriceInUSD } from '../../util/symbolUtil'
import { areAllValuesNonEmpty, isMetSearchStringCriteria, toNumberWithSmartPrecision } from '../../util/util'
import { getPortfolioNames } from '../../util/accountUtil'
import { getNotional, getRiskRatioThresholdByPositionItem } from '../../util/tradingUtil'
import TableColumnSelector, { TableColumnStruct } from '../common/tableColomnSelector/TableColomnSelector'

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const EXPIRY_PERPETUAL = 'Perpetual'

const GROUP_BYS = {
    NONE: 'NONE',
    ACCOUNT: 'ACCOUNT',
    SYMBOL: 'SYMBOL',
    EXCHANGE: 'EXCHANGE',
    EXPIRY: 'EXPIRY',
    ASSET: 'ASSET',
    CROSS_MARGIN_GROUP: 'CROSS_MARGIN_GROUP'
}

const GROUP_SORT_BYS = {
    NAME: 'NAME',
    ABS_NOTIONAL_SUM: 'ABS_NOTIONAL_SUM',
    NOTIONAL_SUM: 'NOTIONAL_SUM',
    FUNDING_AMOUNT_SUM: 'FUNDING_AMOUNT_SUM',
    FUNDING_AMOUNT_PERCENTAGE: 'FUNDING_AMOUNT_PERCENTAGE',
    MIN_ABS_EFFECTIVE_RATIO: 'MIN_ABS_EFFECTIVE_RATIO'
}

const SORT_ORDERS = {
    ASC: 'ASC',
    DESC: 'DESC'
}

const TABLE_ROW_TYPES = {
    POSITION: 'POSITION',
    GROUP: 'GROUP'
}

const TABLE_COLUMNS = [
    TableColumnStruct({
        key: 'expiry',
        name: 'EXPIRY'
    }),
    TableColumnStruct({
        key: 'fundingRate',
        name: 'FUNDING'
    }),
    TableColumnStruct({
        key: 'fundingAmount',
        name: 'FUNDING AMT'
    }),
    TableColumnStruct({
        key: 'greek',
        name: 'GREEK'
    }),
    TableColumnStruct({
        key: 'timestamp',
        name: 'TIME'
    }),
    TableColumnStruct({
        key: 'longPosition',
        name: 'LONG POS'
    }),
    TableColumnStruct({
        key: 'shortPosition',
        name: 'SHORT POS'
    }),
    TableColumnStruct({
        key: 'longAvgCost',
        name: 'L AVG COST'
    }),
    TableColumnStruct({
        key: 'shortAvgCost',
        name: 'S AVG COST'
    }),
    TableColumnStruct({
        key: 'margin',
        name: 'MARGIN'
    }),
    TableColumnStruct({
        key: 'leverage',
        name: 'LEVERAGE'
    }),
    TableColumnStruct({
        key: 'unrealizedPNL',
        name: 'UPL'
    }),
    TableColumnStruct({
        key: 'homeNotional',
        name: 'HOME NOTIONAL'
    }),
    TableColumnStruct({
        key: 'liquidationPrice',
        name: 'LIQ.'
    }),
    TableColumnStruct({
        key: 'currentPrice',
        name: 'CUR. PRICE'
    }),
    TableColumnStruct({
        key: 'ratio',
        name: 'RATIO'
    }),
    TableColumnStruct({
        key: 'riskRatioThresholdReference',
        name: 'R Ref.'
    }),
    TableColumnStruct({
        key: 'notional',
        name: 'NOTIONAL'
    }),
    TableColumnStruct({
        key: 'crossMarginGroup',
        name: 'Cross Margin Group'
    }),
    TableColumnStruct({
        key: 'adjustedPositionUSD',
        name: 'Adj. POS (USD)'
    }),
    TableColumnStruct({
        key: 'marginBufferUSD',
        name: 'MAR BUF (USD)'
    }),
    TableColumnStruct({
        key: 'crossLiquidationRatio',
        name: 'Cross Liq. Ratio'
    }),
    TableColumnStruct({
        key: 'adjustedPositionUpdated',
        name: 'Adj. POS Updated'
    }),
    TableColumnStruct({
        key: 'marginBufferUpdated',
        name: 'MAR BUF Updated'
    }),
    TableColumnStruct({
        key: 'liquidationRatioUpdated',
        name: 'Liq. Ratio Updated'
    }),
    TableColumnStruct({
        key: 'effectiveRatio',
        name: 'EFF Ratio'
    }),
    TableColumnStruct({
        key: 'effectiveUpdated',
        name: 'EFF Updated'
    })
]

const TABLE_COLUMN_WIDTH = {
    account: 150,
    symbol: 220,
    expiry: 100,
    fundingRate: 150,
    fundingAmount: 120,
    greek: 130,
    timestamp: 80,
    longPosition: 80,
    shortPosition: 80,
    longAvgCost: 100,
    shortAvgCost: 100,
    margin: 100,
    leverage: 60,
    unrealizedPNL: 100,
    homeNotional: 100,
    liquidationPrice: 100,
    currentPrice: 100,
    ratio: 100,
    riskRatioThresholdReference: 80,
    notional: 120,
    crossMarginGroup: 150,
    adjustedPositionUSD: 100,
    marginBufferUSD: 100,
    crossLiquidationRatio: 100,
    adjustedPositionUpdated: 110,
    marginBufferUpdated: 110,
    liquidationRatioUpdated: 110,
    effectiveRatio: 100,
    effectiveUpdated: 100
}

const ALL = 'ALL'

const getSessionStorageGroupBy = () => {
    return sessionStorage.positionTableGroupBy || Object.keys(GROUP_BYS)[0]
}

const updateSessionStorageGroupBy = (groupBy) => {
    if (Object.keys(GROUP_BYS).includes(groupBy)) {
        sessionStorage.positionTableGroupBy = groupBy
    }
}

const getSessionStorageGroupSortBy = () => {
    return sessionStorage.positionTableGroupSortBy || Object.keys(GROUP_SORT_BYS)[0]
}

const updateSessionStorageGroupSortBy = (groupSortBy) => {
    if (Object.keys(GROUP_SORT_BYS).includes(groupSortBy)) {
        sessionStorage.positionTableGroupSortBy = groupSortBy
    }
}

const getSessionStorageTableSortBy = () => {
    return sessionStorage.positionTableSortBy
}

const updateSessionStorageTableSortBy = (tableSortable) => {
    sessionStorage.positionTableSortBy = tableSortable
}

const getSessionStorageTableSortOrder = () => {
    return sessionStorage.positionTableSortOrder || SORT_ORDERS.ASC
}

const updateSessionStorageTableSortOrder = (tableSortOrder) => {
    if (Object.keys(SORT_ORDERS).includes(tableSortOrder)) {
        sessionStorage.positionTableSortOrder = tableSortOrder
    }
}

const getSessionStoragePortfolioName = () => {
    const portfolioNames = getPortfolioNames()
    const portfolioName = sessionStorage.positionTablePortfolioName
    return portfolioNames.includes(portfolioName) ? portfolioName : ALL
}

const updateSessionStoragePortfolioName = (portfolioName) => {
    sessionStorage.positionTablePortfolioName = portfolioName
}

const getStorageSelectedColumnKeys = (workspaceComponentId) => {
    const keys = localStorage[`position-table-selected-column-keys--${workspaceComponentId}`]
    return _.isNil(keys) ? _.map(TABLE_COLUMNS, tableColumn => tableColumn.key) : _.compact((keys || '').split(','))
}

const updateStorageSelectedColumnKeys = (workspaceComponentId, keys=[]) => {
    localStorage[`position-table-selected-column-keys--${workspaceComponentId}`] = keys.join(',')
}

const PositionFundingAmount = ({ value=0, currency='', valueInUSD=0, multiplier=1 }) => {
    return {
        value,
        currency,
        valueInUSD,
        multiplier
    }
}

const PositionNotional = ({ value=0, currency='', valueInUSD=0 }) => {
    return {
        value,
        currency,
        valueInUSD
    }
}

const CurrencyNotionalSum = ({ currency='', positiveSum=0, negativeSum=0 }) => {
    return {
        currency,
        positiveSum,
        negativeSum
    }
}

const CurrencyFundingAmountSum = ({ currency='', positiveSum=0, negativeSum=0 }) => {
    return {
        currency,
        positiveSum,
        negativeSum
    }
}

const PositionStats = ({ size=0, notionalSumInUSD=0, absoluteNotionalSumInUSD=0, currencyNotionalSums={}, fundingAmountSumInUSD=0, minEffectiveRatioAbsolute={}, currencyFundingAmountSums={}, tokenToGreekMaps={} }) => {
    return {
        size,
        notionalSumInUSD,
        absoluteNotionalSumInUSD,
        currencyNotionalSums,
        fundingAmountSumInUSD,
        minEffectiveRatioAbsolute,
        currencyFundingAmountSums,
        tokenToGreekMaps
        // tokenToGreekMaps: {
        //     BTC: {
        //         delta: 0,
        //         gamma: 0,
        //         theta: 0,
        //         vega: 0
        //     },
        //     ...
        // }
    }
}

const TablePositionGroupData = ({ name, positionStats={} }) => {
    return {
        name,
        positionStats
    }
}

const PositionRowData = ({ account, symbol, asset, expiry, fundingRateInHouse, fundingRate, fundingAmount, greek, timestamp, 
    longPosition, shortPosition, longAvgCost, shortAvgCost, margin, leverage, 
    unrealizedPNL, homeNotional, liquidationPrice, currentPrice, ratio, riskRatioThresholdReference, notional, crossMarginGroup,
    adjustedPositionUSD, marginBufferUSD, crossLiquidationRatio, adjustedPositionUpdated, marginBufferUpdated, liquidationRatioUpdated, effectiveRatio, effectiveUpdated }) => {
    return {
        account,
        symbol,
        asset,
        expiry,
        fundingRateInHouse,
        fundingRate,
        fundingAmount,
        greek,
        timestamp,
        longPosition,
        shortPosition,
        longAvgCost,
        shortAvgCost,
        margin,
        leverage,
        unrealizedPNL,
        homeNotional,
        liquidationPrice,
        currentPrice,
        ratio,
        riskRatioThresholdReference,
        notional,
        crossMarginGroup,
        adjustedPositionUSD,
        marginBufferUSD,
        crossLiquidationRatio,
        adjustedPositionUpdated,
        marginBufferUpdated, 
        liquidationRatioUpdated, 
        effectiveRatio, 
        effectiveUpdated
    }
}

const TableRow = ({ type=TABLE_ROW_TYPES.POSITION, data={} }) => {
    return {
        type,
        data
    }
}

const cachedWorkspaceComponentStates = {}

class PositionTable extends Component {
    constructor (props) {
        super(props)
        this.state = _.get(cachedWorkspaceComponentStates, props.workspaceComponentId, {
            portfolioName: getSessionStoragePortfolioName(),
            groupBy: getSessionStorageGroupBy(),
            groupSortBy: getSessionStorageGroupSortBy(),
            tableSortBy: getSessionStorageTableSortBy(),
            tableSortOrder: getSessionStorageTableSortOrder(),
            searchString: '',
            groupNamesShouldShowItems: [],
            selectedColumnKeys: getStorageSelectedColumnKeys(props.workspaceComponentId)
        })
        this.positionTableNode = null
        this.tableNode = null
        this.isScrollingTable = null
    }

    componentDidUpdate () {
        if (this.tableNode) {
            this.tableNode.recomputeRowHeights()
        }
    }

    componentWillUnmount () {
        const { workspaceComponentId } = this.props
        if (!_.isNil(workspaceComponentId)) {
            cachedWorkspaceComponentStates[workspaceComponentId] = _.cloneDeep(this.state)
        }
    }

    _removeInnerScrollContainerPointerEvents () {
        if (this.positionTableNode && this.positionTableNode.ownerDocument) {
            const containers = this.positionTableNode.ownerDocument.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer')
            if (!_.isEmpty(containers)) {
                containers[0].style.pointerEvents = null
            }
        }
    }

    handleScrollTableMain () {
        if (window.document.hidden) {
            if (this.isScrollingTable) {
                window.clearTimeout(this.isScrollingTable)
            }
            this.isScrollingTable = setTimeout(() => {
                this._removeInnerScrollContainerPointerEvents()
            }, 66)
        }
    }

    getUSDPriceByCoin (coin) {
        const { pricings } = this.props
        return getTokenPriceInUSD(coin, pricings)
        // coin = coin.includes('OPTION') ? coin.replace(/\s+/g, '').split('-')[0].toLowerCase() : coin.toLowerCase()
        // let result = null
        // if (['usdc', 'usdt', 'busd', 'usd'].includes(coin)) {
        //     result = 1
        // } else {
        //     const symbolNamesToInsepct = [`${coin}_usdt_BINANCE_SPT`, `${coin}_usdc_BINANCE_SPT`, `${coin}_usdt_OKEX_SPT`, 
        //         `${coin}_usdt_PHEMEX_SPT`, `${coin}_usdt_COINFLEX_SPT`, `${coin}_usd_COINFLEX_SPT`]

        //     const symbolName = _.find(symbolNamesToInsepct, name => _.has(pricings, name))
        //     const lastPrice = _.has(pricings, `${symbolName}.last`) ? Number(pricings[symbolName].last) : null
        //     result = lastPrice ? (symbolName.split('_')[0] === coin ? lastPrice : (1 / lastPrice)) : null  
        // }
        // return result
    }

    getSeivedPositionRows () {
        const { positions, symbolItems, fundingRates, fundingRatesInHouse, pricings, accountItems, riskRatioThresholds, optionGreek, nakedOptionGreek, liquidationRatios } = this.props
        const { searchString, tableSortBy, tableSortOrder, portfolioName } = this.state
        const BTCUSDIndexLastPrice = _.has(pricings, `btc_usd_OKEX_INDEX.last`) ? Number(pricings['btc_usd_OKEX_INDEX'].last) : null

        const positionRows = positions.map(p => {
            const { account_name: account, product_name: symbol, timestamp, long_position: longPosition, short_position: shortPosition,
                long_avg_cost: longAvgCost, short_avg_cost: shortAvgCost, margin, leverage, unrealized_pnl: unrealizedPNL,
                home_notional: homeNotional, liquidation_price: liquidationPrice } = p
            const { base, quote, exchangeName } = getSymbolAttributeByName(symbol)
            const symbolItem = symbolItems[symbol]
            const pricingItem = pricings[symbol]
            const currentPrice = !_.isNil(pricingItem) 
                ? (pricingItem.last ? Number(pricingItem.last)
                    : (pricingItem.bid && pricingItem.ask) ? (Number(pricingItem.bid) + Number(pricingItem.ask))/2 
                    : null)
                : null
            const notionalValue = getNotional({ 
                symbolItem, 
                quantity: Number(longPosition) - Number(shortPosition),
                price: currentPrice,
                BTCUSDIndexLastPrice
            })
            const fundingRate = fundingRates[symbol], fundingRateInHouse = fundingRatesInHouse[symbol]
            
            const _fundingRate = areAllValuesNonEmpty([fundingRateInHouse?.funding_rate_calc, fundingRateInHouse?.funding_interval])
                ? BigNumber(fundingRateInHouse.funding_rate_calc).times(8).div(fundingRateInHouse.funding_interval).toNumber()
                : fundingRate?.current_funding_rate
            const fundingAmountValue = !_.isNil(_fundingRate)
                ? -Number(notionalValue) * Number(_fundingRate) * (['FTX', 'COINFLEX', 'AEVO'].includes(exchangeName) ? 8 : 1)
                : null
            const fundingAmount = PositionFundingAmount({
                value: fundingAmountValue,
                currency: quote,
                valueInUSD: quote === 'USD' ? fundingAmountValue : fundingAmountValue * this.getUSDPriceByCoin(quote),
                multiplier: ['FTX', 'COINFLEX', 'AEVO'].includes(exchangeName) ? 8 : 1
            })
            const ratio = liquidationPrice && currentPrice 
                ? (Number(liquidationPrice) - Number(currentPrice)) / Number(currentPrice)
                : null
            const riskRatioThreshold = getRiskRatioThresholdByPositionItem(riskRatioThresholds, p)
            const riskRatioThresholdReference = _.has(riskRatioThreshold, 'reference') ? riskRatioThreshold.reference : null
            const notional = PositionNotional({
                value: notionalValue,
                currency: quote,
                valueInUSD: quote === 'USD' ? notionalValue : notionalValue * this.getUSDPriceByCoin(quote)
            })
            const liquidationRatio = _.get(liquidationRatios, `${account}--${symbol}`)
            return PositionRowData({
                account,
                symbol,
                asset: base,
                expiry: getSymbolExpiryDate(symbolItem) || EXPIRY_PERPETUAL,
                fundingRate,
                fundingRateInHouse,
                fundingAmount,
                timestamp,
                greek: base === 'OPTION_CONTRACT'
                    ? (_.get(accountItems, `${account}.portfolio_name`) === 'prop' ? _.get(nakedOptionGreek, symbol) : _.get(optionGreek, symbol))
                    : null,
                longPosition,
                shortPosition,
                longAvgCost,
                shortAvgCost,
                margin,
                leverage,
                unrealizedPNL,
                homeNotional,
                liquidationPrice,
                currentPrice,
                ratio,
                riskRatioThresholdReference,
                notional,
                crossMarginGroup: _.get(liquidationRatio, 'cross_margin_group'),
                adjustedPositionUSD: _.get(liquidationRatio, 'adjusted_position_usd'),
                marginBufferUSD: _.get(liquidationRatio, 'margin_buffer_usd'),
                crossLiquidationRatio: _.get(liquidationRatio, 'cross_liquidation_ratio'),
                adjustedPositionUpdated: _.get(liquidationRatio, 'adjusted_position_updated'),
                liquidationRatioUpdated: _.get(liquidationRatio, 'liquidation_ratio_updated'),
                marginBufferUpdated: _.get(liquidationRatio, 'margin_buffer_updated'),
                effectiveRatio: _.get(liquidationRatio, 'effective_ratio'),
                effectiveUpdated: _.get(liquidationRatio, 'effective_updated')
            })
        })

        const filteredPositionRows = _.filter(positionRows, positionRow => {
            const { account, symbol, expiry } = positionRow
            const accountItem = accountItems[account]
            const targetString = `${account} ${symbol} ${expiry}`
            return isMetSearchStringCriteria(targetString, searchString)
                && (portfolioName === ALL || (_.has(accountItem, 'portfolio_name') && accountItem.portfolio_name === portfolioName))
        })

        const seivedPositionRows = _.sortBy(filteredPositionRows, positionRow => {
            if (tableSortBy === 'account') {
                return positionRow.account
            } else if (tableSortBy === 'symbol') {
                return positionRow.symbol
            } else if (tableSortBy === 'expiry') {
                return moment(positionRow.expiry).valueOf()
            } else if (tableSortBy === 'fundingRate') {
                return _.has(positionRow.fundingRate, 'current_funding_rate') && !_.isNil(positionRow.fundingRate.current_funding_rate) 
                    ? Math.abs(positionRow.fundingRate.current_funding_rate) 
                    : (tableSortOrder === SORT_ORDERS.ASC ? Infinity : -Infinity)
            } else if (tableSortBy === 'fundingAmount') {
                return Math.abs(positionRow.fundingAmount.valueInUSD) || (tableSortOrder === SORT_ORDERS.ASC ? Infinity : -Infinity)
            } else if (tableSortBy === 'longPosition') {
                return Number(positionRow.longPosition)
            } else if (tableSortBy === 'shortPosition') {
                return Number(positionRow.shortPosition)
            } else if (tableSortBy === 'longAvgCost') {
                return Number(positionRow.longAvgCost)
            } else if (tableSortBy === 'shortAvgCost') {
                return Number(positionRow.shortAvgCost)
            } else if (tableSortBy === 'margin') {
                return Number(positionRow.margin)
            } else if (tableSortBy === 'leverage') {
                return Number(positionRow.leverage)
            } else if (tableSortBy === 'unrealizedPNL') {
                return Math.abs(positionRow.unrealizedPNL)
            } else if (tableSortBy === 'homeNotional') {
                return !_.isEmpty(positionRow.homeNotional)
                    ? Math.abs(positionRow.homeNotional)
                    : (tableSortOrder === SORT_ORDERS.ASC ? Infinity : -Infinity)
            } else if (tableSortBy === 'notional') {
                return Math.abs(positionRow.notional.valueInUSD || 0)
            } else if (tableSortBy === 'crossMarginGroup') {
                return positionRow.crossMarginGroup
            } else if (tableSortBy === 'adjustedPositionUSD') {
                return Math.abs(positionRow.adjustedPositionUSD || 0)
            } else if (tableSortBy === 'marginBufferUSD') {
                return Math.abs(positionRow.marginBufferUSD || 0)
            } else if (tableSortBy === 'crossLiquidationRatio') {
                return Number(positionRow.crossLiquidationRatio)
            } else if (tableSortBy === 'timestamp') {
                return moment(positionRow.timestamp).valueOf()
            } else if (tableSortBy === 'adjustedPositionUpdated') {
                return moment(positionRow.adjustedPositionUpdated).valueOf()
            } else if (tableSortBy === 'liquidationRatioUpdated') {
                return moment(positionRow.liquidationRatioUpdated).valueOf()
            } else if (tableSortBy === 'marginBufferUpdated') {
                return moment(positionRow.marginBufferUpdated).valueOf()
            } else if (tableSortBy === 'effectiveRatio') {
                return !_.isNil(positionRow.effectiveRatio) ? Math.abs(positionRow.effectiveRatio) : (tableSortOrder === SORT_ORDERS.ASC ? Infinity : -Infinity)
            } else if (tableSortBy === 'effectiveUpdated') {
                return moment(positionRow.effectiveUpdated).valueOf()
            } else {
                return _.isNumber(positionRow.ratio) 
                    ? Math.abs(positionRow.ratio)
                    : (tableSortOrder === SORT_ORDERS.ASC ? Infinity : -Infinity)
            }
        })
        if (tableSortOrder === SORT_ORDERS.DESC) {
            _.reverse(seivedPositionRows)
        }
        
        return seivedPositionRows
    }

    _getPositionStats (positionRows=[]) {
        const positionStats = PositionStats({ 
            size: positionRows.length
        })
        _.forEach(positionRows, positionRow => {
            const { account, symbol, asset, notional, fundingAmount, greek, effectiveRatio } = positionRow
            const { value: notionalValue, currency: notionalCurrency, valueInUSD: notionalValueInUSD } = notional
            const { value: fundingAmountValue, currency: fundingAmountCurrency, valueInUSD: fundingAmountValueInUSD } = fundingAmount
            if (!_.has(positionStats.currencyNotionalSums, notionalCurrency) && _.isNumber(notionalValue)) {
                positionStats.currencyNotionalSums[notionalCurrency] = CurrencyNotionalSum({
                    currency: notionalCurrency,
                    positiveSum: 0,
                    negativeSum: 0
                })
            }
            if (!_.has(positionStats.currencyFundingAmountSums, fundingAmountCurrency) &&  _.isNumber(fundingAmountValue)) {
                positionStats.currencyFundingAmountSums[fundingAmountCurrency] = CurrencyFundingAmountSum({
                    currency: fundingAmountCurrency,
                    positiveSum: 0,
                    negativeSum: 0
                })
            }
            if (notionalValue > 0) {
                positionStats.currencyNotionalSums[notionalCurrency].positiveSum += notionalValue
            } else if (notionalValue < 0) {
                positionStats.currencyNotionalSums[notionalCurrency].negativeSum += notionalValue
            }
            if (fundingAmountValue > 0) {
                positionStats.currencyFundingAmountSums[fundingAmountCurrency].positiveSum += fundingAmountValue
            } else if (fundingAmountValue < 0) {
                positionStats.currencyFundingAmountSums[fundingAmountCurrency].negativeSum += fundingAmountValue
            }
            positionStats.notionalSumInUSD += Number(notionalValueInUSD)
            positionStats.absoluteNotionalSumInUSD += Math.abs(Number(notionalValueInUSD))
            positionStats.fundingAmountSumInUSD += Number(fundingAmountValueInUSD)
            
            if (_.isNumber(effectiveRatio)) {
                const _effectiveRatioAbs = Math.abs(effectiveRatio)
                const _currentValue = positionStats.minEffectiveRatioAbsolute?.value
                if (_.isNil(_currentValue) || _effectiveRatioAbs < _currentValue) {
                    positionStats.minEffectiveRatioAbsolute = {
                        value: _effectiveRatioAbs,
                        account,
                        symbol
                    }
                }
            }

            if (asset === 'OPTION_CONTRACT') {
                if (!_.has(positionStats.tokenToGreekMaps, notionalCurrency)) {
                    positionStats.tokenToGreekMaps[notionalCurrency] = {
                        delta: 0,
                        gamma: 0,
                        theta: 0,
                        vega: 0
                    }
                }
                positionStats.tokenToGreekMaps[notionalCurrency].delta += _.get(greek, 'delta', 0)
                positionStats.tokenToGreekMaps[notionalCurrency].gamma += _.get(greek, 'gamma', 0)
                positionStats.tokenToGreekMaps[notionalCurrency].theta += _.get(greek, 'theta', 0)
                positionStats.tokenToGreekMaps[notionalCurrency].vega += _.get(greek, 'vega', 0)
            }
        })

        return positionStats
    }

    getTableRows (seivedPositionRows=[]) {
        const { groupBy, groupSortBy, groupNamesShouldShowItems } = this.state
        let tableRows = []
        if (groupBy === GROUP_BYS.NONE) {
            tableRows = _.map(seivedPositionRows, positionRow => TableRow({
                type: TABLE_ROW_TYPES.POSITION,
                data: positionRow
            }))
        } else {
            const positionGroups = _.groupBy(seivedPositionRows, positionRow => {
                if (groupBy === GROUP_BYS.ACCOUNT) {
                    return positionRow.account
                } else if (groupBy === GROUP_BYS.SYMBOL) {
                    return positionRow.symbol
                } else if (groupBy === GROUP_BYS.EXPIRY) {
                    return positionRow.expiry
                } else if (groupBy === GROUP_BYS.EXCHANGE) {
                    const { exchangeName } = getSymbolAttributeByName(positionRow.symbol)
                    return exchangeName
                } else if (groupBy === GROUP_BYS.CROSS_MARGIN_GROUP) {
                    return positionRow.crossMarginGroup || 'Unkown Group'
                } else {
                    return positionRow.asset
                }
            })
            const positionGroupStats = _.reduce(positionGroups, (result, positionRows, groupKey) => {
                result[groupKey] = this._getPositionStats(positionRows)
                return result
            }, {})
            const positionGroupStatsPairs = _.toPairs(positionGroupStats)
            const sortedPositionGroupStatsPairs = _.sortBy(positionGroupStatsPairs, pair => {
                const { 0: groupKey, 1: positionStats } = pair
                if (groupSortBy === GROUP_SORT_BYS.ABS_NOTIONAL_SUM) {
                    return -Number(positionStats.absoluteNotionalSumInUSD)
                } if (groupSortBy === GROUP_SORT_BYS.NOTIONAL_SUM) {
                    return -Math.abs(positionStats.notionalSumInUSD)
                } else if (groupSortBy === GROUP_SORT_BYS.FUNDING_AMOUNT_SUM) {
                    return -Math.abs(positionStats.fundingAmountSumInUSD)
                } else if (groupSortBy === GROUP_SORT_BYS.FUNDING_AMOUNT_PERCENTAGE) {
                    return -Math.abs(positionStats.fundingAmountSumInUSD/positionStats.notionalSumInUSD)
                } else if (groupSortBy === GROUP_SORT_BYS.MIN_ABS_EFFECTIVE_RATIO) {
                    return positionStats.minEffectiveRatioAbsolute?.value
                } else {
                    return groupKey
                }
            })
            _.forEach(sortedPositionGroupStatsPairs, pair => {
                const { 0: groupKey, 1: positionStats } = pair
                const positionRows = positionGroups[groupKey]
                tableRows.push(
                    TableRow({
                        type: TABLE_ROW_TYPES.GROUP,
                        data: TablePositionGroupData({
                            name: groupKey,
                            positionStats
                        })
                    })
                )
                if (groupNamesShouldShowItems.includes(groupKey)) {
                    tableRows.push(
                        ..._.map(positionRows, positionRow => TableRow({
                            type: TABLE_ROW_TYPES.POSITION,
                            data: positionRow
                        }))
                    )
                }
            })
        }
        return tableRows
    }

    Header (tableRows=[]) {
        const { workspaceComponentId } = this.props
        const { searchString, portfolioName, groupBy, groupSortBy, groupNamesShouldShowItems, selectedColumnKeys } = this.state
        const groupSortByNames = {
            [GROUP_SORT_BYS.NAME]: 'Name',
            [GROUP_SORT_BYS.ABS_NOTIONAL_SUM]: 'Absolute Notional SUM',
            [GROUP_SORT_BYS.NOTIONAL_SUM]: 'Notional SUM',
            [GROUP_SORT_BYS.FUNDING_AMOUNT_SUM]: 'Funding AMT SUM',
            [GROUP_SORT_BYS.FUNDING_AMOUNT_PERCENTAGE]: 'Funding AMT %',
            [GROUP_SORT_BYS.MIN_ABS_EFFECTIVE_RATIO]: 'Min Abs Effective Ratio'
        }
        const portfolioNames = getPortfolioNames()
        const portfolioOptions = _.map(_.concat(ALL, portfolioNames), name => {
            return {
                value: name,
                name: name
            }
        })
        const groupByOptions = _.map(GROUP_BYS, groupBy => {
            return {
                value: groupBy,
                name: _.startCase(_.toLower(_.replace(groupBy, /_/g, ' ')))
            }
        })
        const groupSortByOptions = _.map(GROUP_SORT_BYS, groupSortBy => {
            return {
                value: groupSortBy,
                name: groupSortByNames[groupSortBy] || groupSortBy
            }
        })
        return (
            <div className='position-table--header'>
                <div className='position-table--header--criteria-groups'>
                    <div className='position-table--header--criteria-item'>
                        <span>{'Portfolio'}</span>
                        <SearchSelect 
                            hideSearchBar
                            value={portfolioName}
                            options={portfolioOptions} 
                            onChange={(newOption) => { 
                                updateSessionStoragePortfolioName(newOption.value)
                                this.setState({ 
                                    portfolioName: getSessionStoragePortfolioName()
                                })
                            }} />
                    </div>
                    <div className='position-table--header--criteria-item'>
                        <span>{'Group By'}</span>
                        <SearchSelect 
                            hideSearchBar
                            value={groupBy}
                            options={groupByOptions} 
                            onChange={(newOption) => { 
                                updateSessionStorageGroupBy(newOption.value)
                                this.setState({ 
                                    groupBy: getSessionStorageGroupBy(),
                                    groupNamesShouldShowItems: []
                                })
                            }} />
                    </div>
                    {groupBy !== GROUP_BYS.NONE && <div className='position-table--header--criteria-item'>
                        <span>{'Group Sort By'}</span>
                        <SearchSelect 
                            hideSearchBar
                            value={groupSortBy}
                            options={groupSortByOptions} 
                            onChange={(newOption) => { 
                                updateSessionStorageGroupSortBy(newOption.value)
                                this.setState({ groupSortBy: getSessionStorageGroupSortBy() })
                            }} />
                    </div>}
                </div>
                {groupBy !== GROUP_BYS.NONE && <button className='position-table--header--toggle-group-button' onClick={() => {
                    if (_.isEmpty(groupNamesShouldShowItems)) {
                        const groupRows = _.filter(tableRows, tableRow => tableRow.type === TABLE_ROW_TYPES.GROUP)
                        this.setState({ groupNamesShouldShowItems: groupRows.map(groupRow => groupRow.data.name) })
                    } else {
                        this.setState({ groupNamesShouldShowItems: [] })
                    }
                }}>
                    {_.isEmpty(groupNamesShouldShowItems) ? 'Expand All' : 'Collapse All'}
                    {_.isEmpty(groupNamesShouldShowItems) ? <FiChevronsDown /> : <FiChevronsUp />}
                </button>}
                <button className='position-table--header--reset-button'
                    onClick={() => {
                        updateSessionStorageGroupBy(GROUP_BYS.NONE)
                        updateSessionStorageGroupSortBy(GROUP_SORT_BYS.NAME)
                        this.setState({
                            groupBy: getSessionStorageGroupBy(),
                            groupSortBy: getSessionStorageGroupSortBy(),
                            searchString: '',
                            groupNamesShouldShowItems: []
                        })
                    }}>{'RESET'}</button>
                <input className='position-table--header--search-input' 
                    placeholder={'Search Account, Symbol, Expiry'}
                    spellCheck={false}
                    value={searchString} 
                    onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                <div className='position-table--header--column-selector'>
                    <TableColumnSelector 
                        columns={TABLE_COLUMNS}
                        selectedKeys={selectedColumnKeys} 
                        canHaveEmptySelectedKeys
                        onChangeSelectedKeys={(keys) => {
                            updateStorageSelectedColumnKeys(workspaceComponentId, keys)
                            this.setState({ selectedColumnKeys: getStorageSelectedColumnKeys(workspaceComponentId) })
                        }} />
                </div>
            </div>
        )
    }

    PositionStats (positionStats = PositionStats({})) {
        const { notionalSumInUSD, absoluteNotionalSumInUSD, currencyNotionalSums, fundingAmountSumInUSD, currencyFundingAmountSums, minEffectiveRatioAbsolute, tokenToGreekMaps } = positionStats
        const SideSplit = (positiveSum=0, negativeSum=0) => {
            return (positiveSum !== 0 || negativeSum !== 0) ? ( 
                <div className='position-table--position-stats--popup--side-split'>
                    {'( '}
                    <span className='positive'>{`+${BigNumber(positiveSum || 0).gte(1) ? BigNumber(positiveSum).toFormat(0, 1) : BigNumber(positiveSum || 0).toPrecision(2)}`}</span>
                    {', '}
                    <span className='negative'>{`${BigNumber(negativeSum || 0).lte(-1) ? BigNumber(negativeSum).toFormat(0, 1) : BigNumber(negativeSum || 0).toPrecision(2)}`}</span>
                    {' )'}
                </div>) : null
        }
    
        return (
            <div className='position-table--position-stats'>
                <div className='position-table--position-stats--item'>
                    <label>{'Abs Notional SUM'}</label>
                    <div>{!_.isNil(absoluteNotionalSumInUSD) ? `$${BigNumber(absoluteNotionalSumInUSD).toFormat(0, 1) }`: 'N/A'}</div>
                </div>
                <div className='position-table--position-stats--item' 
                    onClick={(e) => {
                        if (e.target && e.target.className.includes('clickable')) {
                            e.stopPropagation()
                        }
                    }}>
                    <label>{'Net Notional SUM'}</label>
                    <Popup className='position-table--position-stats--popup'
                        disabled={_.isEmpty(currencyNotionalSums)}
                        on={'click'}
                        trigger={
                            <div className={(BigNumber(notionalSumInUSD || 0).gt(0) ? 'positive' 
                                : BigNumber(notionalSumInUSD || 0).lt(0) ? 'negative' 
                                : 'zero-value') + (!_.isEmpty(currencyNotionalSums) ? ' clickable' : '')}>
                                {!_.isNil(notionalSumInUSD) ? `$${BigNumber(notionalSumInUSD).toFormat(0, 1)}` : 'N/A'}</div>
                        }>
                        <div className='position-table--positions-stats--popup--main'
                            onClick={(e) => { e.stopPropagation() }}>
                            {_.map(currencyNotionalSums, (currencySum) => {
                                const { currency, positiveSum, negativeSum } = currencySum
                                const netValue = BigNumber(positiveSum || 0).plus(negativeSum || 0).toNumber()
                                const currencyPrice = this.getUSDPriceByCoin(currency)
                                return (
                                    <div className='position-table--position-stats--popup--item' key={currency}>
                                        <label>{_.toUpper(currency || '')}</label>
                                        <div>
                                            {Math.abs(netValue) >= 1 ? BigNumber(netValue).toFormat(0, 1) : BigNumber(netValue).toPrecision(2, 1)}
                                            {SideSplit(positiveSum, negativeSum)}
                                            <span className='position-table--position-stats--popup--item--price'>{`Price: $${BigNumber(currencyPrice).toFixed(2, 1)}`}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Popup>
                </div>
                {!_.isEmpty(currencyFundingAmountSums) && <div className='position-table--position-stats--item'
                    onClick={(e) => {
                        if (e.target && e.target.className.includes('clickable')) {
                            e.stopPropagation()
                        }
                    }}>
                    <label>{'Funding Amount SUM'}</label>
                    <Popup className='position-table--position-stats--popup'
                        disabled={_.isEmpty(currencyFundingAmountSums)}
                        on={'click'}
                        trigger={
                            <div>
                                <div className={(BigNumber(fundingAmountSumInUSD || 0).gt(0) ? 'positive' 
                                    : BigNumber(fundingAmountSumInUSD || 0).lt(0) ? 'negative' 
                                    : 'zero-value') + (!_.isEmpty(currencyFundingAmountSums) ? ' clickable' : '')}>
                                    {!_.isNil(fundingAmountSumInUSD) ? `$${BigNumber(fundingAmountSumInUSD).toFormat(0, 1)}` : 'N/A'}
                                </div>
                                <span className='position-table--position-stats--popup--funding-rate'>{'(' + BigNumber(fundingAmountSumInUSD || 0).div(notionalSumInUSD).times(-10000).toFixed(2, 1) + '%%)'}</span>
                            </div>
                        }>
                        <div className='position-table--position-stats--popup--main'
                            onClick={(e) => { e.stopPropagation() }}>
                            {_.map(currencyFundingAmountSums, (currencySum) => {
                                const { currency, positiveSum, negativeSum } = currencySum
                                const netValue = BigNumber(positiveSum || 0).plus(negativeSum || 0).toNumber()
                                const currencyPrice = this.getUSDPriceByCoin(currency)
                                return (
                                    <div className='position-table--position-stats--popup--item' key={currency}>
                                        <label>{_.toUpper(currency || '')}</label>
                                        <div>
                                            {Math.abs(netValue) >= 1 ? BigNumber(netValue).toFormat(0, 1) : BigNumber(netValue).toPrecision(2, 1)}
                                            {SideSplit(positiveSum, negativeSum)}
                                            <span className='position-table--position-stats--popup--item--price'>{`Price: $${BigNumber(currencyPrice).toFixed(2, 1)}`}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Popup>
                </div>}
                {!_.isNil(minEffectiveRatioAbsolute?.value) &&
                <div className='position-table--position-stats--item'>
                    <label>{'Min Abs EFF Ratio'}</label>
                    <div className='position-table--position-stats--item--min-abs-eff-ratio'>
                        {`${BigNumber(minEffectiveRatioAbsolute.value).times(100).toFixed(2, 0)}%`}
                        <span>{`(${minEffectiveRatioAbsolute.symbol} - ${minEffectiveRatioAbsolute.account})`}</span>
                    </div>
                </div>}
                {!_.isEmpty(tokenToGreekMaps) && <div className='position-table--position-stats--greeks'>
                    {_.map(tokenToGreekMaps, (greeks, token) => {
                        return _.map(greeks, (value, key) => {
                            const isDelta = key === 'delta'
                            let tokenPrice = null, displayVlaue = value
                            if (isDelta) {
                                tokenPrice = this.getUSDPriceByCoin(token)
                                displayVlaue = BigNumber(value || 0).times(tokenPrice || 0).toString()
                            }
                            return (
                                <div className='position-table--position-stats--item' key={`${token}--${key}`}>
                                    <label>{`${token} ${_.capitalize(key)}`}</label>
                                    <Popup className='position-table--position-stats--popup'
                                        on={'click'}
                                        disabled={!isDelta}
                                        trigger={<div className={isDelta ? 'clickable' : null}>{key !== 'gamma' ? `$${BigNumber(displayVlaue || 0).toFormat(0, 1)}` : BigNumber(displayVlaue || 0).toPrecision(2, 1)}</div>}>
                                        <div className='position-table--position-stats--popup--main'
                                            onClick={(e) => { e.stopPropagation() }}>
                                            <div className='position-table--position-stats--popup--item'>
                                                <label>{_.toUpper(token || '')}</label>
                                                <div>
                                                    {Math.abs(value) >= 1 ? BigNumber(value).toFormat(0, 1) : BigNumber(value).toPrecision(2, 1)}
                                                    <span className='position-table--position-stats--popup--item--price'>{`Price: $${BigNumber(tokenPrice).toFixed(2, 1)}`}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Popup>
                                    
                                </div>
                            )
                        })
                    })}
                </div>}
            </div>
        ) 
    }

    PositionGroupRow (positionGroupData=TablePositionGroupData({})) {
        const { name, positionStats } = positionGroupData
        return (
            <div className='position-table--position-group-row'>
                <div className='position-table--position-group-row--name'>
                    {name}
                    <span className='position-table--position-group-row--name--size'>{`(${positionStats.size})`}</span>
                </div>
                <div className='position-table--position-group-row--stats'>
                    {this.PositionStats(positionStats)}
                </div>
            </div>
        )
    }

    FundingRateData (positionRowData=PositionRowData({})) {
        const { symbol, fundingRate, fundingRateInHouse } = positionRowData
        const precision = Math.max(getSymbolFundingRatePrecision(symbol) - 2, 2)

        const Section = (name, value) => {
            return (
                <section className='position-table--funding-rate--section'>
                    <span className='position-table--funding-rate--section--name'>{name}</span>
                    <div className={'position-table--funding-rate--section--value' + (value > 0 ? ' positive' : ' negative')}>
                        {(Number(value) * 10000).toFixed(precision) + '%%'}
                    </div>
                </section>
            )
        }

        if (!_.isNil(fundingRate) || !_.isNil(fundingRateInHouse)) {
            const inHouseFundingRate = _.get(fundingRateInHouse, 'funding_rate_calc')
            const inHouseFundingRateName = fundingRateInHouse?.next_funding_time ? `In-house ${moment(fundingRateInHouse?.next_funding_time).format('HH:mm')}` : `In-house`
        
            const isDynamicFundingRate = isDynamicFundingRateSymbol(symbol)
            const currentFundingRate = _.get(fundingRate, 'current_funding_rate')
            const currentFundingRateName = fundingRate?.timestamp && !isDynamicFundingRate 
                ? moment(fundingRate.timestamp).format('HH:mm') 
                : 'Current'

            const indicativeFundingRate = _.get(fundingRate, 'indicative_funding_rate')
            const indicativeFundingRateName = fundingRate?.next_period && !isDynamicFundingRate 
                ? moment(fundingRate.next_period).format('HH:mm') 
                : 'Next'

            return (
                <div className='position-table--funding-rate'>
                    {!_.isNil(inHouseFundingRate) ? Section(inHouseFundingRateName, inHouseFundingRate)
                        : !_.isNil(currentFundingRate) ? Section(currentFundingRateName, currentFundingRate)
                            : null}
                    {!_.isNil(indicativeFundingRate) && Section(indicativeFundingRateName, indicativeFundingRate)}
                </div>
            )
        } else {
            return null
        }
    }

    render () {
        const { tableSortBy, tableSortOrder, groupNamesShouldShowItems, selectedColumnKeys } = this.state
        const { symbolItems } = this.props
        const seivedPositionRows = this.getSeivedPositionRows()
        const tableRows = this.getTableRows(seivedPositionRows)
        const positionStats = this._getPositionStats(seivedPositionRows)

        return (
            <div className='position-table' ref={(node) => { this.positionTableNode = node }}>
                {this.Header(tableRows)}
                <div className='position-table--overall-stats'>
                    {this.PositionStats(positionStats)}
                </div>
                <div className='position-table--main'>
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                ref={(node) => { this.tableNode = node }}
                                className='position-table--table'
                                headerClassName={'position-table--table--header'}
                                headerHeight={27}
                                width={Math.max(width, TABLE_COLUMN_WIDTH.account + TABLE_COLUMN_WIDTH.symbol + _.reduce(selectedColumnKeys, (result, columnKey) => { return result + (TABLE_COLUMN_WIDTH[columnKey] || 100) }, 0))}
                                height={height}
                                rowCount={tableRows.length}
                                rowGetter={({ index }) => tableRows[index]}
                                rowClassName={({ index }) => { 
                                    let className = 'position-table--table--row'
                                    const tableRow = tableRows[index]
                                    if (tableRow) {
                                        className += (tableRow.type === TABLE_ROW_TYPES.GROUP ? ' group-row' : ' position-row')
                                        className += (index % 2 === 1 ? ' odd-row' : ' even-row')
                                    } 
                                    return className
                                }}
                                rowHeight={({ index }) => { 
                                    const tableRow = tableRows[index]
                                    return tableRow.type === TABLE_ROW_TYPES.GROUP ? 88 
                                        : _.has(tableRow, `data.greek`) && !_.isNil(tableRow.data.greek) && selectedColumnKeys.includes('greek') ? 88 
                                        : 43
                                }}
                                overscanRowCount={5}
                                noRowsRenderer={() => ( <div className='position-table--no-content'>{'There is no matched position.'}</div> )}
                                sort={({ sortBy: newTableSortBy }) => {
                                    const newTableSortOrder = _.isEqual(tableSortBy, newTableSortBy)
                                        ? (tableSortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC)
                                        : tableSortOrder
                                    updateSessionStorageTableSortBy(newTableSortBy)
                                    updateSessionStorageTableSortOrder(newTableSortOrder)
                                    this.setState({
                                        tableSortBy: getSessionStorageTableSortBy(),
                                        tableSortOrder: getSessionStorageTableSortOrder()
                                    })
                                }}
                                onScroll={() => { this.handleScrollTableMain() }}
                                onRowClick={({ rowData }) => {
                                    if (rowData.type === TABLE_ROW_TYPES.GROUP) {
                                        const { name } = rowData.data
                                        const newGroupsNamesShouldShowItems = groupNamesShouldShowItems.includes(name)
                                            ? _.without(groupNamesShouldShowItems, name)
                                            : _.concat(groupNamesShouldShowItems, name)
                                        this.setState({ groupNamesShouldShowItems: newGroupsNamesShouldShowItems })
                                    }
                                }}>
                                <Column dataKey={'account'}
                                    label={'ACCOUNT'}
                                    headerClassName={'sortable' + (tableSortBy === 'account' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.account}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.GROUP
                                            ? this.PositionGroupRow(data)
                                            : data.account
                                    }} />
                                <Column dataKey={'symbol'}
                                    label={'SYMBOL'}
                                    headerClassName={'sortable' + (tableSortBy === 'symbol' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.symbol}
                                    flexGrow={1}
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION
                                            ? data.symbol
                                            : null
                                    }} />
                                {selectedColumnKeys.includes('expiry') && <Column dataKey={'expiry'}
                                    label={'EXPIRY'}
                                    headerClassName={'sortable' + (tableSortBy === 'expiry' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.expiry}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION
                                            ? data.expiry
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('fundingRate') && <Column dataKey={'fundingRate'}
                                    label={'FUNDING'}
                                    headerClassName={'sortable' + (tableSortBy === 'fundingRate' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.fundingRate}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION
                                            ? this.FundingRateData(data)
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('fundingAmount') && <Column dataKey={'fundingAmount'}
                                    label={'FUNDING AMT'}
                                    headerClassName={'sortable' + (tableSortBy === 'fundingAmount' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.fundingAmount}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && Number(data.fundingAmount.value) !== 0
                                            ? (
                                                <div className='position-table--table--funding-amount'>
                                                    <span className='currency'>{data.fundingAmount.currency}</span>
                                                    <span className={data.fundingAmount.value > 0 ? 'positive' : 'negative'}>
                                                        {toNumberWithSmartPrecision({ 
                                                            number: data.fundingAmount.value,
                                                            defaultPrecision: 2,
                                                            shouldReturnLocalString: true
                                                        })}
                                                    </span>
                                                    {data.fundingAmount.multiplier > 1 && 
                                                    <span className='position-table--table--funding-amount--multiplier'>{`(${data.fundingAmount.multiplier}x)`}</span>}
                                                </div>
                                            )
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('greek') && <Column dataKey={'greek'}
                                    label={'GREEK'}
                                    width={TABLE_COLUMN_WIDTH.greek}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.greek)
                                            ? (
                                                <div className='position-table--table--greek'>
                                                    <div className='position-table--table--greek--item'>
                                                        <label>{'Delta'}</label>
                                                        <span>{!_.isNil(data.greek.delta) ? toNumberWithSmartPrecision({ number: data.greek.delta }) : 'N/A'}</span>
                                                    </div>
                                                    <div className='position-table--table--greek--item'>
                                                        <label>{'Gamma'}</label>
                                                        <span>{!_.isNil(data.greek.gamma) ? toNumberWithSmartPrecision({ number: data.greek.gamma }) : 'N/A'}</span>
                                                    </div>
                                                    <div className='position-table--table--greek--item'>
                                                        <label>{'Theta'}</label>
                                                        <span>{!_.isNil(data.greek.theta) ? toNumberWithSmartPrecision({ number: data.greek.theta }) : 'N/A'}</span>
                                                    </div>
                                                    <div className='position-table--table--greek--item'>
                                                        <label>{'Vega'}</label>
                                                        <span>{!_.isNil(data.greek.vega) ? toNumberWithSmartPrecision({ number: data.greek.vega }) : 'N/A'}</span>
                                                    </div>
                                                    <div className='position-table--table--greek--item'>
                                                        <label>{'Updated'}</label>
                                                        <span>{_.has(data, `greek.last_updated`) && !_.isNil(data.greek.last_updated) ? moment(data.greek.last_updated).format('HH:mm:ss') : 'N/A'}</span>
                                                    </div>
                                                </div>
                                            )
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('timestamp') && <Column dataKey={'timestamp'}
                                    label={'TIME'}
                                    headerClassName={'sortable' + (tableSortBy === 'timestamp' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.timestamp}
                                    flexGrow={0} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION
                                            ? moment(data.timestamp).format('HH:mm:ss')
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('longPosition') && <Column dataKey={'longPosition'}
                                    label={'LONG POS'}
                                    headerClassName={'sortable' + (tableSortBy === 'longPosition' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.longPosition}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION
                                            ? BigNumber(data.longPosition || 0).gte(1000) ? BigNumber(data.longPosition).toFormat(0) : toNumberWithSmartPrecision({ number: data.longPosition })
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('shortPosition') && <Column dataKey={'shortPosition'}
                                    label={'SHORT POS'}
                                    headerClassName={'sortable' + (tableSortBy === 'shortPosition' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.shortPosition}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION
                                            ? BigNumber(data.shortPosition || 0).gte(1000) ? BigNumber(data.shortPosition).toFormat(0) : toNumberWithSmartPrecision({ number: data.shortPosition })
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('longAvgCost') && <Column dataKey={'longAvgCost'}
                                    label={'L AVG COST'}
                                    headerClassName={'sortable' + (tableSortBy === 'longAvgCost' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.longAvgCost}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        if (type === TABLE_ROW_TYPES.POSITION) {
                                            const { symbol, longAvgCost } = data
                                            const pricePrecision = getPricePrecisionBySymbolItem(symbolItems[symbol])
                                            return Number(longAvgCost) > 0
                                                ? toNumberWithSmartPrecision({
                                                    number: longAvgCost,
                                                    defaultPrecision: pricePrecision,
                                                    shouldReturnLocalString: true
                                                }) 
                                                : ''
                                        } else {
                                            return null
                                        }
                                    }} />}
                                {selectedColumnKeys.includes('shortAvgCost') && <Column dataKey={'shortAvgCost'}
                                    label={'S AVG COST'}
                                    headerClassName={'sortable' + (tableSortBy === 'shortAvgCost' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.shortAvgCost}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        if (type === TABLE_ROW_TYPES.POSITION) {
                                            const { symbol, shortAvgCost } = data
                                            const pricePrecision = getPricePrecisionBySymbolItem(symbolItems[symbol])
                                            return Number(shortAvgCost) > 0 
                                                ? toNumberWithSmartPrecision({
                                                    number: shortAvgCost,
                                                    defaultPrecision: pricePrecision,
                                                    shouldReturnLocalString: true
                                                }) 
                                                : ''
                                        } else {
                                            return null
                                        }
                                    }} />}
                                {selectedColumnKeys.includes('margin') && <Column dataKey={'margin'}
                                    label={'MARGIN'}
                                    headerClassName={'sortable' + (tableSortBy === 'margin' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.margin}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isEmpty(data.margin)
                                            ? toNumberWithSmartPrecision({ number: data.margin, shouldReturnLocalString: true })
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('leverage') && <Column dataKey={'leverage'}
                                    label={'LEVERAGE'}
                                    headerClassName={'sortable' + (tableSortBy === 'leverage' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.leverage}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.leverage) && !_.isEmpty(data.leverage)
                                            ? toNumberWithSmartPrecision({ number: data.leverage, shouldApplyFloorValue: true })
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('unrealizedPNL') && <Column dataKey={'unrealizedPNL'}
                                    label={'UPL'}
                                    headerClassName={'sortable' + (tableSortBy === 'unrealizedPNL' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.unrealizedPNL}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isEmpty(data.unrealizedPNL)
                                            ? <span className={data.unrealizedPNL > 0 ? 'positive' : 'negative'}>
                                                {toNumberWithSmartPrecision({ number: data.unrealizedPNL, shouldReturnLocalString: true })}
                                            </span>
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('homeNotional') && <Column dataKey={'homeNotional'}
                                    label={'HOME NOTIONAL'}
                                    headerClassName={'sortable' + (tableSortBy === 'homeNotional' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.homeNotional}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isEmpty(data.homeNotional)
                                            ? <span className={data.homeNotional > 0 ? 'positive' : 'negative'}>
                                                {Math.abs(data.homeNotional) >= 1000 ? BigNumber(data.homeNotional).toFormat(0) : toNumberWithSmartPrecision({ number: data.homeNotional })}
                                            </span>
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('liquidationPrice') && <Column dataKey={'liquidationPrice'}
                                    label={'LIQ.'}
                                    disableSort
                                    width={TABLE_COLUMN_WIDTH.liquidationPrice}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        if (type === TABLE_ROW_TYPES.POSITION) {
                                            const { symbol, liquidationPrice } = data
                                            const pricePrecision = getPricePrecisionBySymbolItem(symbolItems[symbol])
                                            return !_.isEmpty(liquidationPrice) 
                                                ? toNumberWithSmartPrecision({
                                                    number: liquidationPrice,
                                                    defaultPrecision: pricePrecision,
                                                    shouldReturnLocalString: true
                                                }) 
                                                : ''
                                        } else {
                                            return null
                                        }
                                    }} />}
                                {selectedColumnKeys.includes('currentPrice') && <Column dataKey={'currentPrice'}
                                    label={'CUR. PRICE'}
                                    disableSort
                                    width={TABLE_COLUMN_WIDTH.currentPrice}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION
                                            ? data.currentPrice
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('ratio') && <Column dataKey={'ratio'}
                                    label={'RATIO'}
                                    headerClassName={'sortable' + (tableSortBy === 'ratio' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.ratio}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        if (type === TABLE_ROW_TYPES.POSITION) {
                                            const shouldHighlight = _.isNumber(data.ratio) && _.isNumber(data.riskRatioThresholdReference) && Math.abs(data.ratio) < Math.abs(data.riskRatioThresholdReference)
                                            return (
                                                <span style={{ color: shouldHighlight ? '#fd818a' : '#ddd' }}>
                                                    {data.ratio ? `${(Number(data.ratio) * 100).toFixed(2)}%` : 'N/A'}
                                                </span>
                                            )
                                        } else {
                                            return null
                                        }
                                    }} />}
                                {selectedColumnKeys.includes('riskRatioThresholdReference') && <Column dataKey={'riskRatioThresholdReference'}
                                    label={'R Ref.'}
                                    disableSort
                                    width={TABLE_COLUMN_WIDTH.riskRatioThresholdReference}
                                    flexGrow={1} 
                                    flexShrink={0}
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION
                                            ? (data.riskRatioThresholdReference ? `${(Number(data.riskRatioThresholdReference) * 100).toFixed(0)}%` : 'N/A')
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('notional') && <Column dataKey={'notional'}
                                    label={'NOTIONAL'}
                                    headerClassName={'sortable' + (tableSortBy === 'notional' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.notional}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && Number(data.notional.value) !== 0
                                            ? (
                                                <div className='position-table--table--notional'>
                                                    <span className='currency'>{data.notional.currency}</span>
                                                    <span className={data.notional.value > 0 ? 'positive' : 'negative'}>
                                                        {Math.abs(data.notional.value) >= 1000 ? BigNumber(data.notional.value).toFormat(0) : toNumberWithSmartPrecision({ 
                                                            number: data.notional.value,
                                                            defaultPrecision: 2,
                                                            shouldReturnLocalString: true
                                                        })}
                                                    </span>
                                                </div>
                                            )
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('crossMarginGroup') && <Column dataKey={'crossMarginGroup'}
                                    label={'Cross Margin Group'}
                                    headerClassName={'sortable' + (tableSortBy === 'crossMarginGroup' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.crossMarginGroup}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.crossMarginGroup)
                                            ? data.crossMarginGroup
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('adjustedPositionUSD') && <Column dataKey={'adjustedPositionUSD'}
                                    label={'Adj. POS (USD)'}
                                    headerClassName={'sortable' + (tableSortBy === 'adjustedPositionUSD' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.adjustedPositionUSD}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.adjustedPositionUSD)
                                            ? BigNumber(data.adjustedPositionUSD).toFormat(0, 1)
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('marginBufferUSD') && <Column dataKey={'marginBufferUSD'}
                                    label={'MAR BUF (USD)'}
                                    headerClassName={'sortable' + (tableSortBy === 'marginBufferUSD' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.marginBufferUSD}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.marginBufferUSD)
                                            ? BigNumber(data.marginBufferUSD).toFormat(0, 1)
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('crossLiquidationRatio') && <Column dataKey={'crossLiquidationRatio'}
                                    label={'Cross Liq. Ratio'}
                                    headerClassName={'sortable' + (tableSortBy === 'crossLiquidationRatio' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.crossLiquidationRatio}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.crossLiquidationRatio)
                                            ? BigNumber(data.crossLiquidationRatio).toFixed(4, 0)
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('adjustedPositionUpdated') && <Column dataKey={'adjustedPositionUpdated'}
                                    label={'Adj. POS Updated'}
                                    headerClassName={'sortable' + (tableSortBy === 'adjustedPositionUpdated' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.adjustedPositionUpdated}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.adjustedPositionUpdated)
                                            ? moment(data.adjustedPositionUpdated).format('HH:mm:ss')
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('marginBufferUpdated') && <Column dataKey={'marginBufferUpdated'}
                                    label={'MAR BUF Updated'}
                                    headerClassName={'sortable' + (tableSortBy === 'marginBufferUpdated' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.marginBufferUpdated}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.marginBufferUpdated)
                                            ? moment(data.marginBufferUpdated).format('HH:mm:ss')
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('liquidationRatioUpdated') && <Column dataKey={'liquidationRatioUpdated'}
                                    label={'Liq. Ratio Updated'}
                                    headerClassName={'sortable' + (tableSortBy === 'liquidationRatioUpdated' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.liquidationRatioUpdated}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.liquidationRatioUpdated)
                                            ? moment(data.liquidationRatioUpdated).format('HH:mm:ss')
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('effectiveRatio') && <Column dataKey={'effectiveRatio'}
                                    label={'EFF Ratio'}
                                    headerClassName={'sortable' + (tableSortBy === 'effectiveRatio' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.effectiveRatio}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.effectiveRatio)
                                            ?  (
                                                <span style={{ color: !_.isNil(data.effectiveRatio) && !_.isNil(data.ratio) && BigNumber(data.effectiveRatio).abs().minus(BigNumber(data.ratio).abs()).gte(0.0001) ? '#eeda85' : null }}>
                                                    {`${BigNumber(data.effectiveRatio).times(100).toFixed(2, 0)}%`}
                                                </span>
                                            )
                                            : null
                                    }} />}
                                {selectedColumnKeys.includes('effectiveUpdated') && <Column dataKey={'effectiveUpdated'}
                                    label={'EFF Updated'}
                                    headerClassName={'sortable' + (tableSortBy === 'effectiveUpdated' ? ' sorted' : '')}
                                    width={TABLE_COLUMN_WIDTH.effectiveUpdated}
                                    flexGrow={1}
                                    flexShrink={0} 
                                    cellRenderer={({ rowData }) => {
                                        const { type, data } = rowData
                                        return type === TABLE_ROW_TYPES.POSITION && !_.isNil(data.effectiveUpdated)
                                            ? moment(data.effectiveUpdated).format('HH:mm:ss')
                                            : null
                                    }} />}
                            </Table>
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}

PositionTable.propTypes = {
    workspaceComponentId: PropTypes.string,
    positions: PropTypes.array.isRequired,
    symbolItems: PropTypes.object.isRequired,
    fundingRates: PropTypes.object.isRequired,
    fundingRatesInHouse: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    riskRatioThresholds: PropTypes.object.isRequired,
    optionGreek: PropTypes.object.isRequired,
    nakedOptionGreek: PropTypes.object.isRequired,
    liquidationRatios: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        positions: state.trading.positions,
        symbolItems: state.symbol.items,
        fundingRates: state.symbol.fundingRates,
        fundingRatesInHouse: state.symbol.fundingRatesInHouse,
        pricings: state.symbol.pricings,
        accountItems: state.account.items,
        riskRatioThresholds: state.trading.riskRatioThresholds,
        optionGreek: state.trading.optionGreek,
        nakedOptionGreek: state.trading.nakedOptionGreek,
        liquidationRatios: state.trading.liquidationRatios
    }
}
export default connect(mapStateToProps)(PositionTable)