import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

export const INSTRUMENT_TYPES = {
    SPOT: 'SPOT',
    FUTURE: 'FUTURE',
    SWAP: 'SWAP',
    INDEX: 'INDEX',
    OPTION: 'OPTION',
    SPREAD: 'SPREAD',
    REPO: 'REPO',
    GRFQ: 'GRFQ',
    UNKOWN: 'UNKOWN'
}

export const SYMBOL_SWITCH_TYPES = {
    BUY_SELL_TO_OPEN_CLOSE: 'BUY_SELL_TO_OPEN_CLOSE',
    BUY_SELL: 'BUY_SELL'
}

export const getNormalizedCurrencyName = (currencyName) => {
    currencyName = currencyName.toUpperCase()
    return currencyName === 'XBT' ? 'BTC' 
        : ['USD', 'HUSD'].includes(currencyName) ? 'USDT'
        : currencyName
}

export const getFuturesMonthByCode = (monthCode) => {
    const codes = {
        F: 0, 
        G: 1,
        H: 2,
        J: 3,
        K: 4,
        M: 5, 
        N: 6,
        Q: 7,
        U: 8,
        V: 9,
        X: 10,
        Z: 11
    }
    return codes[monthCode]
}

export const getSymbolAttributeByName = (symbolName) => {

    // const getLastFridayByMonthMoment = (monthMoment) => {
    //     let lastDay = monthMoment.endOf('month').endOf('day')
    //     return lastDay.subtract((lastDay.day() + 2) % 7, 'days').format('YYYY-MM-DD')
    // }

    const tokens = (symbolName || '').split('_')
    const originalExchangeName = tokens[tokens.length - 2]
    const exchangeName = _.startsWith(originalExchangeName, 'IB') ? 'IB' : originalExchangeName
    const originalType = tokens[tokens.length - 1]

    const instrumentType = ['INDEX', 'INDEX2', 'ASTIDX'].includes(originalType) ? INSTRUMENT_TYPES.INDEX
        : originalType === 'SPT' ? INSTRUMENT_TYPES.SPOT
        : ['FTW', 'FNW', 'FQR', 'FNQ', 'FUT'].includes(originalType) ? INSTRUMENT_TYPES.FUTURE
        : originalType === 'SWAP' ? INSTRUMENT_TYPES.SWAP
        : ['CALL', 'PUT', 'QMOV'].includes(originalType) ? INSTRUMENT_TYPES.OPTION
        : originalType === 'SPR' ? INSTRUMENT_TYPES.SPREAD
        : originalType === 'REPO' ? INSTRUMENT_TYPES.REPO
        : originalType === 'GRFQ' ? INSTRUMENT_TYPES.GRFQ
        : INSTRUMENT_TYPES.UNKOWN

    const switchType = ((['OKEX', 'HUOBIFUT'].includes(exchangeName) && [INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType)) || _.endsWith(symbolName, '_usdt_BYBIT_SWAP')) 
        ? SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE : SYMBOL_SWITCH_TYPES.BUY_SELL // To Be Depreciated

    const isCompositInstrument = (symbolName || '').includes('&')

    let compositInstrumentCurrency = null, compositSymbolsWithMultiplier = [], compositSymbols = [], compositSymbolMultipliers=[]
    if (isCompositInstrument) {
        const cutoffIndex = symbolName.lastIndexOf(`_${originalExchangeName}_${originalType}`)
        if (cutoffIndex > -1) {
            compositSymbolsWithMultiplier = _.compact(symbolName.substring(0, cutoffIndex).split('&'))
            _.forEach(compositSymbolsWithMultiplier, symbolWithDirection => {
                const symbolStartIndex = _.findIndex([...symbolWithDirection], char => !['+', '-', '1'].includes(char) && !(Number(char) > 0))
                const multiplierString = symbolWithDirection.substring(0, symbolStartIndex)
                const multiplier = _.isEmpty(multiplierString) ? 1 : multiplierString === '-' ? -1 : Number(multiplierString)
                compositSymbols.push(symbolWithDirection.substring(symbolStartIndex))
                compositSymbolMultipliers.push(multiplier)
            })
            compositInstrumentCurrency = getSymbolAttributeByName(compositSymbols[0]).base
        }
    }

    const baseMultiplierMatch = tokens[0].match(/1(0{3,})$/)

    let base = isCompositInstrument ? 'UNIT'
        : instrumentType === INSTRUMENT_TYPES.OPTION ? 'OPTION_CONTRACT'
        : [INSTRUMENT_TYPES.OPTION, INSTRUMENT_TYPES.SPREAD].includes(instrumentType) ? tokens[0].substring(0, 3).toUpperCase()
        : baseMultiplierMatch ? _.toUpper(tokens[0].substring(0, baseMultiplierMatch.index))
        : tokens.length === 4 ? tokens[0].toUpperCase() 
        : tokens[0].length > 6 && _.endsWith(tokens[0], 'USD', tokens[0].length - 3) ? tokens[0].substring(0, tokens[0].length - 6)
        : tokens[0].length > 7 && _.endsWith(tokens[0], 'USDT', tokens[0].length - 3) ? tokens[0].substring(0, tokens[0].length - 7)
        : tokens[0].length > 4 && _.endsWith(tokens[0], 'USDT') ? tokens[0].substring(0, tokens[0].length - 4)
        : tokens[0].substring(0, tokens[0].length - 3)

    base = ['XBT', 'MBTC'].includes(base) ? 'BTC'
        : ['METH'].includes(base) ? 'ETH'
        : base === 'TSTBSC' ? 'TST'
        : base

    const quote = isCompositInstrument ? 'QUOTE'
        : instrumentType === INSTRUMENT_TYPES.OPTION ? tokens[0].substring(0, 3).toUpperCase() 
        : [INSTRUMENT_TYPES.OPTION, INSTRUMENT_TYPES.SPREAD].includes(instrumentType) ? 'USD' 
        : tokens.length === 4 ? tokens[1].toUpperCase()
        : exchangeName === 'BITMEX' && base !== 'BTC' && !tokens[0].includes('USD') ? 'BTC'
        : tokens[0].length > 7 && _.endsWith(tokens[0], 'USDT', tokens[0].length - 3) ? 'USDT'
        : 'USD'

    const symbolAttributes = {
        base,
        quote,
        pairName: `${getNormalizedCurrencyName(base)}/${base !== 'USDT' ? getNormalizedCurrencyName(quote) : quote}`,
        originalExchangeName,
        exchangeName,
        originalType,
        instrumentType,
        isCompositInstrument,
        compositSymbolsWithMultiplier,
        compositSymbols,
        compositSymbolMultipliers,
        compositInstrumentCurrency,
        switchType // To Be Depreciated
        //settlementDate
    } 

    if (instrumentType === INSTRUMENT_TYPES.OPTION) {
        symbolAttributes.optionExpiryDate = moment(tokens[0].substring(tokens[0].length - 6, tokens[0].length), 'YYMMDD').format('YYYY-MM-DD')
        symbolAttributes.optionStrikePrice = tokens[1]
    }

    return symbolAttributes
}

export const getSymbolSwitchType = (symbolName) => {
    // const { items: accountItems } = store.getState().account
    const { exchangeName, instrumentType } = getSymbolAttributeByName(symbolName)
    // const accountItem = _.get(accountItems, accountName)

    return (['HUOBIFUT'].includes(exchangeName) && [INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType))
        // || (exchangeName === 'OKEX' && [INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType) && _.get(accountItem, 'is_portfolio_margin') !== '1')
        // || _.endsWith(symbolName, '_usdt_BYBIT_SWAP')
        ? SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE 
        : SYMBOL_SWITCH_TYPES.BUY_SELL
}

export const getPairsWithValidIndex = (symbolPricings) => {
    const pairs = {}
    Object.values(symbolPricings).forEach((pricingItem) => {
        const { base, quote, instrumentType } = getSymbolAttributeByName(pricingItem.symbolName)
        if (instrumentType === INSTRUMENT_TYPES.INDEX && Number(pricingItem.last) > 0) {
            const normalizedQuote = quote === 'USD' && base !== 'USDT' ? 'USDT' : quote
            const pairName = `${base}/${normalizedQuote}`
            pairs[pairName] = {
                base,
                quote,
                pairName,
                symbolName: pricingItem.symbolName,
                value: Number(pricingItem.last)
            }
        }
    })
    return pairs
}

export const getSymbolFundingRatePrecision = (symbolName) => {
    const symbolAttribute = getSymbolAttributeByName(symbolName)
    const { exchangeName } = symbolAttribute
    return exchangeName === 'DERIBIT' ? 5 
        : ['FTX', 'COINFLEX'].includes(exchangeName) ? 4
        : 3
}

export const getSymbolExpiryDate = (symbolItem) => {
    const expiryMoment = _.has(symbolItem, 'expiry') ? moment(symbolItem.expiry) : null
    return expiryMoment && expiryMoment.year() !== 1970 ? expiryMoment.format('YYYY-MM-DD') : null
}

export const isDynamicFundingRateSymbol = (symbolName) => {
    const { exchangeName } = getSymbolAttributeByName(symbolName)
    return ['DERIBIT'].includes(exchangeName)
}

export const getPricePrecisionBySymbolItem = (symbolItem) => {
    let pricePrecision = 2
    if (!_.isNil(symbolItem)) {
        const { symbol_name: symbolName, price_tick: priceTick } = symbolItem
        const { instrumentType } = getSymbolAttributeByName(symbolName)
        pricePrecision = Number(priceTick) > 0 && (priceTick || '').includes('.') ? priceTick.split('.')[1].length
            : Number(priceTick) >= 1 ? 0
            : instrumentType === INSTRUMENT_TYPES.REPO ? 5
            : 5
    }
    return pricePrecision
}

export const getQuantityPrecisionBySymbolItem = (symbolItem) => {
    let quantityPrecision = 2
    if (!_.isNil(symbolItem)) {
        const { tick_size: tickSize } = symbolItem
        quantityPrecision = Number(tickSize) > 0 && Number(tickSize) < 1 && (tickSize || '').includes('.') ? tickSize.split('.')[1].length
            : Number(tickSize) >= 1 ? 0
            : 2
    }
    return quantityPrecision
}

export const getTokenPriceInCurrency = ({ token='', lastPricePerSymbol={}, currency='USDT', shouldReferencePerpPrice=false, shouldUseDirectConversionOnly=false }) => {
    const formattedToken = token.includes('-OPTION') ? _.toUpper(token.split('-')[0]) : _.toUpper(token)
    const EXCHANGE_NAMES_TO_INSPECT = ['BINANCE', 'OKEX', 'COINFLEX', 'INTERNAL', 'IBBATS', 'IBARCA', 'GATE', 'HYPERLIQ', 'BYBIT', 'BITGET']
    const INTERMEDIARY_CURRENCIES = _.without(['USDT', 'USDC', 'USD'], currency)
    let price = null
    if (formattedToken === currency || 
        (formattedToken === 'USD' && ['BUSD', 'USDC', 'USDT'].includes(currency))) {
        price = 1
    } else {
        price = _.reduce(EXCHANGE_NAMES_TO_INSPECT, (result, exchangeName) => {
            if (_.isNil(result)) {
                const directLastPrice = Number(_.get(lastPricePerSymbol, `${_.toLower(formattedToken)}_${_.toLower(currency)}_${exchangeName}_SPT`))
                const inverseLastPrice = Number(_.get(lastPricePerSymbol, `${_.toLower(currency)}_${_.toLower(formattedToken)}_${exchangeName}_SPT`))

                if (directLastPrice > 0) {
                    result = directLastPrice
                } else if (inverseLastPrice > 0) {
                    result = BigNumber(1).div(inverseLastPrice).toNumber()
                }
            }
            return result
        }, null)

        if (_.isNil(price) && shouldReferencePerpPrice) {
            const directLastPrice = Number(_.get(lastPricePerSymbol, `${_.toLower(formattedToken)}_${_.toLower(currency)}_BNBFUTA_SWAP`))
            const inverseLastPrice = Number(_.get(lastPricePerSymbol, `${_.toLower(currency)}_${_.toLower(formattedToken)}_BNBFUTA_SWAP`))
            const directLastPriceWithMultiplier = Number(_.get(lastPricePerSymbol, `${_.toLower(formattedToken)}1000_${_.toLower(currency)}_BNBFUTA_SWAP`))

            if (directLastPrice > 0) {
                price = directLastPrice
            } else if (inverseLastPrice > 0) {
                price = BigNumber(1).div(inverseLastPrice).toNumber()
            } else if (directLastPriceWithMultiplier > 0) {
                price = BigNumber(directLastPriceWithMultiplier).div(1000).toNumber()
            }
        }

        if (_.isNil(price) && !shouldUseDirectConversionOnly) {
            _.forEach(INTERMEDIARY_CURRENCIES, intermediaryCurrency => {
                if (_.isNil(price)) {
                    const intermediaryCurrencyPrice = getTokenPriceInCurrency({ token: intermediaryCurrency, lastPricePerSymbol, currency, shouldReferencePerpPrice, shouldUseDirectConversionOnly: true })
                    const tokenPriceInIntermediaryCurrency = getTokenPriceInCurrency({ token, lastPricePerSymbol, currency: intermediaryCurrency, shouldReferencePerpPrice, shouldUseDirectConversionOnly: true })    
                    if (Number(intermediaryCurrencyPrice) > 0 && Number(tokenPriceInIntermediaryCurrency) > 0) {
                        price = BigNumber(tokenPriceInIntermediaryCurrency).times(intermediaryCurrencyPrice).toNumber()
                    }
                }
            })
        }
    }
    return price
}

export const getTokenPriceInUSD = (token='', pricings={}) => {
    token = token.includes('OPTION') ? _.toLower(token.replace(/\s+/g, '').split('-')[0]) : _.toLower(token)
    let price = null
    if (['usdc', 'busd', 'usd'].includes(token)) {
        price = 1
    } else if (token === 'usdt' && _.has(pricings, 'usdc_usdt_BINANCE_SPT.last')) {
        price = BigNumber(1).div(_.get(pricings, 'usdc_usdt_BINANCE_SPT.last')).toString()
    } else {
        const symbolNamesToInsepct = [`${token}_usdc_BINANCE_SPT`, `${token}_usd_COINFLEX_SPT`, 
            `${token}_usdt_BINANCE_SPT`, `${token}_usdt_OKEX_SPT`, `${token}_usdt_PHEMEX_SPT`, `${token}_usdt_COINFLEX_SPT`,
            `${token}_usdt_INTERNAL_SPT`, `${token}_usd_IBBATS_SPT`, `${token}_usd_IBARCA_SPT`, `${token}_usd_GATE_SPT`,
            `${token}_usdc_HYPERLIQ_SPT`, `${token}_usdt_BYBIT_SPT`, `${token}_usdt_BNBFUTA_SWAP`, `${token}_usdt_BYBIT_SWAP`, `${token}_usdt_BITGET_SWAP`]

        const selectedSymbolName = _.find(symbolNamesToInsepct, symbolName => {
            const lastPrice = _.get(pricings, `${symbolName}.last`)
            return BigNumber(lastPrice || 0).gt(0)
        })

        if (!_.isNil(selectedSymbolName)) {
            const { quote } = getSymbolAttributeByName(selectedSymbolName)
            price = _.get(pricings, `${selectedSymbolName}.last`)

            if (quote === 'USDT' && _.has(pricings, 'usdc_usdt_BINANCE_SPT.last')) {
                const usdtLastPrice = BigNumber(1).div(_.get(pricings, 'usdc_usdt_BINANCE_SPT.last')).toString()
                price = BigNumber(price).times(usdtLastPrice).toString()
            }
            price = Number(price)
        }
    }
    
    return price || getTokenPriceInCurrency({
        token,
        lastPricePerSymbol: _.mapValues(pricings, _pricing => Number(_pricing.last)),
        currency: 'USDC',
        shouldReferencePerpPrice: true
    })
}

export const getOptionSymbolUnderlying = (symbolName='') => {
    const symbolTokens = symbolName.split('_')
    const token = symbolTokens[0].substring(0, symbolTokens[0].length - 6)
    return _.toUpper(token) || 'UNKOWN'
}

export const getTokens = (symbolItems={}) => {
    const _tokens = _.reduce(symbolItems, (result, symbolItem) => {
        const { base, quote } = getSymbolAttributeByName(symbolItem.symbol_name)
        result[base] = base
        result[quote] = quote
        return result
    }, {})
    return _.compact(_.keys(_tokens))
}